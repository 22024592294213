<template>
    <div v-if="data.resourceData.username">
        <div class="d-flex">
            <router-link class="me-2" :to="{ name: 'ProfilePage', params:{'username': data.resourceData.user.username, 'userId': data.resourceData.id}}">
                <img :src="getAvatarUrl(data.resourceData)" class="rounded-circle template-avatar" width="30"/>
            </router-link>
            <div class="emp-name">{{data.resourceData.first_name}} {{data.resourceData.last_name}}</div>
        </div>
    </div>
    <div v-else>
        <h6>Nema podataka za</h6>
        <h6>ovaj period</h6>
    </div>
        
</template>

<script>
    export default {
        methods: {
            getAvatarUrl(employee) {
                return employee.user.avatar_url?employee.user.avatar_url:require('@/assets/profilna.png')
            },
        }
    }
</script>

<style>

</style>