<template>
    <div>

        <div class="container-xl middle-menu mt-5 mb-5">
            <div class="row">
                <div class="col-md-8">
                    <router-link :to="{ name: 'EmployeesAssignmentTrip'}" class="btn btn-primary">Dodeli vozila</router-link>
                </div>
                <div class="col-md-4">
  
                </div>
            </div>

            <div class="timeline-container mt-2">

                <ejs-schedule ref="tripEmpTimeline" id='Schedule' width='100%' height='500px' :eventSettings='eventSettings' :selectedDate='selectedDate' :currentView='currentView' :group='group'
                    class="trip-emp-timeline"
                    :dateFormat='dateFormat'
                    :timeFormat='timeFormat'
                    :timeScale="timeScale"
                    :workHours="workHours"
                    :firstDayOfWeek='1'
                    :navigating="navigatingEvent"
                    :actionComplete='actionCompleteEvent'
                    :eventRendered='onEventRendered'
                    :resourceHeaderTemplate='resourceHeaderTemplate'
                    
                >
                    <e-views>
                        <e-view option='TimelineDay'>Dan</e-view>
                        <e-view option='TimelineDay' value="Test">Dan</e-view>
                        <e-view option='TimelineWeek'></e-view>
                        <e-view option='Month'></e-view>
                        <e-view option='Agenda'></e-view>
                    </e-views>
                    <e-resources>
                        <!-- <e-resource field= 'RoomId' title= 'Room' name= 'Rooms' :dataSource='roomDataSource' textField='RoomText' idField='Id' colorField='RoomColor'>
                        </e-resource> -->
                        <e-resource field='employeeId' title='Passenger' name='Passengers' :allowMultiple='allowMultiple' :dataSource='ownerDataSource'
                        textField='first_name' idField='id'>
                        </e-resource>
                    </e-resources>
                </ejs-schedule>
            </div>
        </div>



    </div>
</template>


<script>
    import UtilService from '@/service/UtilService.js';
    import {mapState} from 'vuex';
    import Vue from 'vue';
    import TransportService from '@/service/TransportService.js';
    import EmployeesTripTimelineHeader from '@/components/schedule-templates/EmployeesTripTimelineHeader.vue'

    import { Week, Month, Agenda, TimelineViews, TimelineMonth } from "@syncfusion/ej2-vue-schedule";

    Vue.prototype.$eventHub = new Vue(); 

    // var resourceHeaderVue = Vue.component("resourceHeader", {
    //     template: '<div class="template-wrap">eee</div>',
    //     data() {
    //         return {
    //             data: {}
    //         };
    //     }
    // });

    export default {

        components: { },

        setup: {
            UtilService
        },
        provide: {
            schedule: [Week, Month, Agenda, TimelineViews, TimelineMonth]
        },

        data: function() {
            return {
                width: '100%',
                height: '550px',
                currentView: 'TimelineDay',
                selectedDate: new Date(),
                group: {
                    resources: ['Passengers']
                },
                allowMultiple: true,
                ownerDataSource: [
                    { first_name: 'Nema naloga za taj datum', id: 0, driver: 0 },
                ],
                eventSettings: {
                    allowEditing: false,
                    allowAdding: false,
                    fields: {
                        id: 'id',
                        subject: { name: 'name' },
                        isAllDay: { name: 'deleted' },
                        startTime: { name: 'start_time' },
                        endTime: { name: 'end_time' }
                    }
                    
                },
                
                timeScale: { enable: true, interval: 60, slotCount: 2 },
                workHours: { highlight: true, start: '07:00', end: '17:00' },

                dateFormat: "dd.MM.yyyy.",
                timeFormat: "HH:mm",

                
                resourceHeaderTemplate: function () {
                    return {
                        template: EmployeesTripTimelineHeader
                    };
                },
            }
        },  
        computed: {
            ...mapState([
                'userSettings',
            ]),

        },

        methods: {

            isReadOnly: function(endDate) {
                return (endDate < new Date(2021, 6, 31, 0, 0));
            },
            onRenderCell: function (args) {
                if (args.element.classList.contains('e-work-cells')) {
                    if (args.date < new Date(2021, 6, 31, 0, 0)) {
                        args.element.setAttribute('aria-readonly', 'true');
                        args.element.classList.add('e-read-only-cells');
                    }
                }
                if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
                    let target = args.element.querySelector('.e-resource-text');
                    target.innerHTML = '<div class="name">Rooms</div><div class="type">Type</div><div class="capacity">Capacity</div>';
                }
            },
            onEventRendered: function (args) {
                if (args.data.id) {
                    let hsl = this.generateColor(args.data.vehicle);

                    if (args.element.className.includes('agenda')) {
                        args.element.children[0].style['border-left'] = '3px solid hsl(' + hsl[0] + 'deg ' + hsl[1] + '% ' + hsl[2] + '%)';
                    } else {
                        args.element.style['background'] = 'hsl(' + hsl[0] + 'deg ' + hsl[1] + '% ' + hsl[2] + '%)';
                    }
                }
            },
            onActionBegin: function (args) {
                if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
                    let data;
                    let scheduleObj = this.$refs.ScheduleObj;
                    if (args.requestType === 'eventCreate') {
                        data = args.data[0];
                    } else if (args.requestType === 'eventChange') {
                        data = args.data;
                    }
                    if (!scheduleObj.isSlotAvailable(data)) {
                        args.cancel = true;
                    }
                }
            },


            scheudleRefreshData() {
                let scheduleObj = this.$refs.tripEmpTimeline.ej2Instances; 

                
                // scheduleObj.timeScale.enable = true;

                scheduleObj.eventSettings.dataSource = this.eventSettings.dataSource;
                scheduleObj.refresh();
                scheduleObj.scrollTo("07:00");

            },

            navigatingEvent() {
            },


            processTimelineData() {
                if (this.ownerDataSource.length == 0) {
                    this.ownerDataSource = [{ first_name: 'Nema naloga za taj datum', id: 0, driver: 0 }]

                } 

            },

            generateEmpTrips(trips) {
                let pseudoId = 1;
                let tripsTimeline = [];

                trips.forEach(trip => {
                    let cTrip = UtilService.deepClone(trip)
                    cTrip.pseudoId = pseudoId++;
                    cTrip.employeeId = trip.driver
                    cTrip.name = "Vozač - " + cTrip.end_location + " (" + cTrip.name + ")";
                    tripsTimeline.push(cTrip)

                    trip.passengers_obj.forEach(passenger => {
                        
                        let cTrip = UtilService.deepClone(trip)
                        cTrip.pseudoId = pseudoId++;
                        cTrip.employeeId = passenger.user_id
                        cTrip.name = "Putnik - " + cTrip.end_location + " (" + cTrip.name + ")"
                        tripsTimeline.push(cTrip)
                    })
                    

                    
                });
                this.eventSettings.dataSource = tripsTimeline;

            },

            actionCompleteEvent(event) {

                if (event.requestType == "viewNavigate") {
                    this.loadTimeline();
                    this.changeGridLines();
                }

                if (event.requestType == "dateNavigate") {
                    this.loadTimeline();
                }

                
                if (event.requestType === "viewNavigate" || event.requestType === "toolBarItemRendered") {
                    let scheduleObj = this.$refs.tripEmpTimeline.ej2Instances
                    let todayElement = scheduleObj.element.querySelector(".e-schedule .e-schedule-toolbar .e-timeline-day .e-tbar-btn-text");
                    todayElement.innerHTML = "Vremenska linija";
                }

            },

            changeGridLines() {
                let scheduleObj = this.$refs.tripEmpTimeline.ej2Instances

                if (scheduleObj.activeView.viewIndex === 0)
                    scheduleObj.timeScale.enable = true;
                else
                    scheduleObj.timeScale.enable = false;
            },

            loadTimeline() {
                let leftBound = this.selectedDate
                let rightBound = this.selectedDate
                if (this.$refs.tripEmpTimeline.ej2Instances.activeView?.renderDates) {
                    // selectedDate = this.$refs.tripEmpTimeline.ej2Instances.selectedDate;
                    leftBound = UtilService.dateToUTC(this.$refs.tripEmpTimeline.ej2Instances.activeView?.renderDates?.at(0))
                    rightBound = UtilService.dateToUTC(this.$refs.tripEmpTimeline.ej2Instances.activeView?.renderDates?.at(-1))



                }

                let data = {
                    // date: selectedDate.toISOString().slice(0,10),
                    startDate: leftBound?.toISOString()?.slice(0,10),
                    endDate: rightBound?.toISOString()?.slice(0,10),
                }
                TransportService.getAllVehicleTripsTimeline(data).then((response) => {
                    this.ownerDataSource = response.data.passengers
                    this.generateEmpTrips(response.data.trips)
                    this.processTimelineData();
                    this.scheudleRefreshData();

                }).catch((error) => {
                    console.log(error);
                })
            },

            generateColor(id) {
                const prime = 257;
                const multiplier = 1000
                let hue = (prime * id * multiplier) % 360
                let saturation = 48
                let lightness = 48

                return [hue, saturation, lightness];
            },



            

        },

        created() {
        },

        mounted() {
            this.loadTimeline();

        },

    }
    
</script>

<style>
    .trip-emp-timeline.e-schedule .e-agenda-view .e-content-wrap table td:first-child {
        width: 200px !important;
    }

</style>

<style scoped>

</style>